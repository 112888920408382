<template>
  <v-skeleton-loader
    type="image"
    v-if="!value"
  />
  <v-card v-else>
    <v-card-title>{{$t('t.MailevaSettings')}}</v-card-title>
    <v-card-text>
      <picker
        document-types="providers"
        :clearable="true"
        :label="$t('t.MailevaProvider')"
        :selected-id.sync="computedMailevaProviderId"
        :dense="false"
        :disabled="!isCompany"
        can-send-maileva-letter
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  data () {
    return {
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataMailevaProviderId: null
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        mailevaProviderId: this.dataMailevaProviderId
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('input', this.lodash.cloneDeep(doc))
      }
    }
  },
  computed: {
    computedMailevaProviderId: {
      get () {
        return this.dataMailevaProviderId
      },
      set (v) {
        this.dataMailevaProviderId = v
        this.dataDocumentEmit()
      }
    }
  },
  props: {
    value: Object,
    isCompany: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler (v) {
        this.dataMailevaProviderId = v?.mailevaProviderId
      },
      immediate: true
    },
    isCompany: {
      handler (v) {
        if (!v) {
          this.dataMailevaProviderId = null
          this.dataDocumentEmit()
        }
      },
      immediate: true
    }
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
  flex-wrap wrap

.v-text-field
  margin-right 1em
</style>
